/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { jsx } from '@emotion/core'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'

import { rhythm, scale } from '../utils/typography'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Description from '../components/Description'

import '../styles/prism.css'
import './post.css'

const listItemStyle = {
  content: "'\\2022'",
  textIndent: '-16px',
  color: '#aaa',
  float: 'left',
}

const Post = ({ data, pageContext }) => {
  const { title } = data.site.siteMetadata
  const post = data.markdownRemark
  const { previous, next } = pageContext

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <main css={{ overflow: 'hidden' }}>
        <article>
          <Header>
            <Navbar title={title} />

            <h1 css={{ marginTop: 0, marginBottom: '.4rem' }}>
              {post.frontmatter.title}
            </h1>

            {post.frontmatter.description && (
              <Description>{post.frontmatter.description}</Description>
            )}

            <div
              css={{
                color: '#888',
                ...scale(-1 / 5),
              }}
            >
              <time dateTime={new Date(post.frontmatter.date).toISOString()}>
                {post.frontmatter.date}
              </time>{' '}
              • {post.timeToRead} min read
            </div>
          </Header>

          <div
            css={{
              margin: '0 auto',
              maxWidth: rhythm(24),
              padding: `${rhythm(2)} ${rhythm(1 / 2)}`,
            }}
          >
            <div
              // Do not change this class name because DocSearch relies on it.
              // See https://github.com/algolia/docsearch-configs/blob/master/configs/francoischalifour.json
              className="post"
              css={{
                letterSpacing: '-.003em',
                '& ul': {
                  marginLeft: '1.6rem',
                  listStyle: 'none',
                  '& li::before': listItemStyle,
                },
                '& ol': {
                  marginLeft: '1.6rem',
                },
              }}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />

            <aside css={{ marginTop: rhythm(2) }}>
              {post.frontmatter.references && (
                <div
                  css={{
                    margin: '3rem -50px',
                    padding: 50,
                    background: 'rgba(38,41,58,.04)',
                    '& li::before': listItemStyle,
                  }}
                >
                  <h3 css={{ marginTop: 0 }}>References</h3>
                  <ul
                    css={{
                      listStyle: 'none',
                      marginLeft: 16,
                      marginBottom: 0,
                    }}
                  >
                    {post.frontmatter.references.map((reference) => (
                      <li key={reference.title}>
                        <a
                          href={reference.link}
                          css={{
                            boxShadow: '0 1px 0 0 currentColor',
                            color: '#555',
                            '&:hover': {
                              boxShadow: 'none',
                            },
                          }}
                        >
                          {reference.title}
                        </a>{' '}
                        <small>
                          (
                          <a
                            href={
                              typeof window !== 'undefined' &&
                              new URL(reference.link).origin
                            }
                            css={{
                              color: '#555',
                            }}
                          >
                            {typeof window !== 'undefined' &&
                              new URL(reference.link).hostname.replace(
                                /^www\./,
                                ''
                              )}
                          </a>
                          )
                        </small>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <nav
                css={{
                  margin: '0 -50px',
                  padding: `${rhythm(1 / 2)} 50px`,
                  borderTop: '1px solid rgba(0, 0, 0, 0.06)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                }}
              >
                <ul
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    listStyle: 'none',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <li
                    css={{
                      marginBottom: 0,
                      '&:not(:empty)::before': {
                        content: '"←"',
                        marginRight: 8,
                        textAlign: 'left',
                      },
                    }}
                  >
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li
                    css={{
                      marginBottom: 0,
                      '&:not(:empty)::after': {
                        content: '"→"',
                        marginLeft: 8,
                        textAlign: 'right',
                      },
                    }}
                  >
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title}
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </aside>
          </div>
        </article>
      </main>

      <Footer {...data.site.siteMetadata} />
    </>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        githubUsername
        twitterUsername
        polyworkUsername
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      timeToRead
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM D, YYYY")
        references {
          title
          link
        }
      }
    }
  }
`
